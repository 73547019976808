<template>
  <LandingPage
    v-if="showLanding"
    v-show="!isLoading"
    :countries="landingCountries"
    @show-home="showLanding = false"
  />
  <HomePage v-else />
</template>

<script>
import HomePage from "@/components/pages/HomePage";
import LandingPage from "@/components/pages/LandingPage";
import countryMng from "@/helper/country";

export default {
  name: "App",
  components: {
    HomePage,
    LandingPage,
  },
  data() {
    return {
      showLanding: false,
      landingCountries: ["IT", "DE", "FR", "NL", "BEFR", "BENL", "AT", "ES"],
      register10Domains: [
        "register10.eu",
        "www.register10.eu",
        "register10.staging.wpsandwatch.com",
        "register10.dev.wpsandwatch.com",
        "localhost:8080", //for testing purposes
      ],
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.isLoading;
    },
  },
  methods: {
    checkLandingVisibility(country) {
      return (
        this.register10Domains.includes(document.location.host) &&
        this.landingCountries.includes(country)
      );
    },
  },
  beforeMount() {
    // Loading is set to false inside "setCountry" action
    this.$store.commit("setLoading", true);

    let country = "";

    if (window.location.href.includes("locale")) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());

      if (params.locale !== "") {
        country = params.locale.toUpperCase();
        this.$store.state.localeQS = country;
      }
    } else {
      country = countryMng.getCountryFromReq();
    }

    // Appends default language to multilang countries
    country = countryMng.getMultiLangDefault(country);

    const name = countryMng.getNameFromCountry(
      this.$store.state.nameCountryMapping,
      country
    );

    this.$store.commit("setCountry", country);
    this.$store.commit("setCountryName", name);

    this.showLanding = this.checkLandingVisibility(country);
  },
};
</script>

<style lang="css">
/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://assets.wpsandwatch.com/frontend/garanplus/fonts/Montserrat-ExtraLight.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://assets.wpsandwatch.com/frontend/garanplus/fonts/Montserrat-ExtraLight.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://assets.wpsandwatch.com/frontend/garanplus/fonts/Montserrat-ExtraLight.woff) format('woff');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://assets.wpsandwatch.com/frontend/garanplus/fonts/Montserrat-ExtraLight.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://assets.wpsandwatch.com/frontend/garanplus/fonts/Montserrat-Regular.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://assets.wpsandwatch.com/frontend/garanplus/fonts/Montserrat-Regular.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://assets.wpsandwatch.com/frontend/garanplus/fonts/Montserrat-Regular.woff) format('woff');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://assets.wpsandwatch.com/frontend/garanplus/fonts/Montserrat-Regular.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://assets.wpsandwatch.com/frontend/garanplus/fonts/Montserrat-SemiBold.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://assets.wpsandwatch.com/frontend/garanplus/fonts/Montserrat-SemiBold.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://assets.wpsandwatch.com/frontend/garanplus/fonts/Montserrat-SemiBold.woff) format('woff');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://assets.wpsandwatch.com/frontend/garanplus/fonts/Montserrat-SemiBold.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://assets.wpsandwatch.com/frontend/garanplus/fonts/Montserrat-Bold.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://assets.wpsandwatch.com/frontend/garanplus/fonts/Montserrat-Bold.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://assets.wpsandwatch.com/frontend/garanplus/fonts/Montserrat-Bold.woff) format('woff');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://assets.wpsandwatch.com/frontend/garanplus/fonts/Montserrat-Bold.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'BarlowCondensed';
  src: url('https://assets.wpsandwatch.com/frontend/garanplus/fonts/BarlowCondensed-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: fallback; /* was swap */
}

</style>

<style lang="scss">
/*@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;400;600;700&display=swap"');*/
/*@import url('https://fonts.googleapis.com/css2?family=Barlow&display=swap');*/

/*@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;600;700&display=swap');*/
/*@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@200;400;600;700&display=swap');*/

// @import './assets/scss/main.scss';
</style>
